import React, { useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Row, Col, Button } from "antd"
import pcLeft from "../../content/assets/pc_left.svg"
import pcRight from "../../content/assets/pc_right.svg"
import wave from "../../content/assets/wave.svg"
import arrow from "../../content/assets/arrow.svg"
import hook from "../../content/assets/hook.svg"
import styles from "./index.module.less"

const layout = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
}

const content = [
  {
    title: "内置多个免费高转化主题",
    p:
      "通过测试对比挑选出高转化主题，可使用主题已经针对所有页面上的转化进行了优化",
    span: "了解更多",
    img: "img1",
  },
  {
    title: "内置多个免费App，店铺功能更强大",
    p:
      "页面弹框，倒计时，数量折扣，交叉销售，一键迁移，个性化推荐，马帮ERP等免费App",
    span: "了解更多",
    img: "img2",
  },
  {
    title: "集成多个主流支付网关",
    p: "目前支持MasterCard/PayPal/Stripe/BlueSnap/Visa",
    span: "了解更多",
    img: "img3",
  },
  {
    title: "可自由定制结账页面",
    p: "支持用户自定义结账页面，同时支持多步结账和一步结账两种方式",
    p2: "自动添加安全信任徽章，提高购物安全性",
    span: "了解更多",
    img: "img4",
    icon: hook,
  },
  {
    title: "支持更多的商品变种数量",
    p: "服装/眼睛等特殊商品，不再受100个变种数量的限制",
    p2: "变种不再是以文字方式显示，缩略图让顾客更容易产生购买冲动",
    span: "了解更多",
    img: "img5",
    icon: hook,
  },
  {
    title: "集成自动电子邮件和SMS营销方案",
    p: "遗弃订单，可向客户发送多达3封电子邮件和短信",
    p2: "商家可在电子邮件模板上自定义个性化内容和品牌Logo",
    span: "了解更多",
    img: "img6",
    icon: hook,
  },
]

const icons = ["circle", "circle2", "slash", "slash2"]

const toHref = () => {
  window.open("https://shopbase.com/?fpr=QsIzbIe")
}

export default ({ data }) => {
  const containerRef = useRef(null)

  return (
    <Layout>
      <SEO title="shopbsup" />
      <div className={styles.banner}>
        <Row style={{ maxWidth: 1064, margin: `0 auto` }} align="middle">
          <Col xs={24} sm={24} md={9} lg={9} xl={9}>
            <Img
              className={styles.title}
              fluid={data.title.childImageSharp.fluid}
              alt="title"
            />
            <h3>
              面向全球商家的<br></br>一站式模块化解决方案
            </h3>
            <Button
              onClick={toHref}
              type="primary"
              style={{
                borderRadius: 6,
                background: `#2954AF`,
                borderColor: `#2954AF`,
                width: 166,
              }}
            >
              开启免费体验
            </Button>
          </Col>
          <Col xs={24} sm={24} md={15} lg={15} xl={15}>
            <Img
              style={{ width: "100%", maxWidth: 670 }}
              fluid={data.banner.childImageSharp.fluid}
              alt="banner"
            />
          </Col>
        </Row>
      </div>
      <div className={styles.pc}>
        <div className={styles.pcText}>
          <div style={{ textAlign: `right` }}>
            <h1>跨境贸易 变得轻松</h1>
            <img src={wave} alt="img" className={styles.wave} />
          </div>
          <p>
            <img src={hook} alt="img" style={{ marginRight: 8 }} />
            更容易入门的外贸建站平台
          </p>
          <p>
            <img src={hook} alt="img" style={{ marginRight: 8 }} />
            多个预制高转化主题，转化率有效提升15%
          </p>
          <p>
            <img src={hook} alt="img" style={{ marginRight: 8 }} />
            定制付费套餐，费用节省30%以上
          </p>
        </div>
        <div className={styles.pcImg}>
          <Img
            style={{ width: "100%", zIndex: 1 }}
            fluid={data.pc.childImageSharp.fluid}
            alt="img"
          />
        </div>
        <div className={styles.pcLeft}>
          <img src={pcLeft} alt="img" style={{ width: `100%` }} />
        </div>
        <div className={styles.pcRight}>
          <img src={pcRight} alt="img" />
        </div>
      </div>
      <div className={styles.intro} ref={containerRef}>
        {content.map(item => {
          const source = data[item.img]
          return item.icon ? (
            <Row
              className={styles.content}
              align="middle"
              justify="space-between"
              key={item.title}
            >
              <Col {...layout} style={{ maxWidth: 385 }}>
                <h3>{item.title}</h3>
                <p>
                  <img src={item.icon} alt="img" style={{ marginRight: 8 }} />
                  {item.p}
                </p>
                <p>
                  <img src={item.icon} alt="img" style={{ marginRight: 8 }} />
                  {item.p2}
                </p>
                <div style={{ marginTop: 50 }} id={item.img}>
                  <a onClick={toHref}>
                    {item.span}
                    <img
                      src={arrow}
                      alt="img"
                      style={{ marginLeft: 6, marginBottom: 0 }}
                    />
                  </a>
                </div>
              </Col>
              <Col {...layout}>
                <Img
                  style={{ maxWidth: 580, textAlign: "right" }}
                  fluid={source.childImageSharp.fluid}
                  alt="img"
                />
              </Col>
            </Row>
          ) : (
            <Row
              className={styles.content}
              align="middle"
              justify="space-between"
              key={item.title}
            >
              <Col {...layout} style={{ maxWidth: 385 }}>
                <h3>{item.title}</h3>
                <p>{item.p}</p>
                <div style={{ marginTop: 50 }} id={item.img}>
                  <a onClick={toHref}>
                    {item.span}
                    <img
                      src={arrow}
                      alt="img"
                      style={{ marginLeft: 6, marginBottom: 0 }}
                    />
                  </a>
                </div>
              </Col>
              <Col {...layout}>
                <Img
                  style={{ maxWidth: 580, textAlign: "right" }}
                  fluid={source.childImageSharp.fluid}
                  alt="img"
                />
              </Col>
            </Row>
          )
        })}
      </div>
      <div className={styles.freeTrail}>
        <div className={styles.freeTrailText}>
          {icons.map(item => {
            const source = data[item]
            return (
              <Img fixed={source.childImageSharp.fixed} alt="img" key={item} />
            )
          })}
          <h1>开始您的14天免费试用</h1>
          <p>立即领取14天免费试用期，无需信用卡</p>
          <Button
            onClick={toHref}
            type="primary"
            style={{
              width: 200,
              borderRadius: 6,
              background: `#2954AF`,
              borderColor: `#2954AF`,
            }}
          >
            开启免费体验
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    title: file(relativePath: { eq: "assets/title.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    banner: file(relativePath: { eq: "assets/banner.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pc: file(relativePath: { eq: "assets/pc.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img1: file(relativePath: { eq: "assets/img1.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "assets/img2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "assets/img3.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "assets/img4.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "assets/img5.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "assets/img6.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    circle: file(relativePath: { eq: "assets/circle.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    circle2: file(relativePath: { eq: "assets/circle2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    slash: file(relativePath: { eq: "assets/slash.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    slash2: file(relativePath: { eq: "assets/slash2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
